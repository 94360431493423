const meta = {
  module: "document-templates"
};

export default [
  {
    path: `/document-templates`,
    redirect: "/document-templates/page/1"
  },
  {
    path: `/document-templates/page`,
    redirect: "/document-templates/page/1"
  },
  {
    path: `/document-templates/page/:page`,
    name: "document-templates",
    component: () =>
      import(
        /* webpackChunkName: "document-templates" */ "@/modules/document-templates/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/document-templates/create`,
    name: "document-templates/create",
    component: () =>
      import(
        /* webpackChunkName: "document-templates/edit" */ "@/modules/document-templates/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/document-templates/edit/:id`,
    name: "document-templates/edit",
    component: () =>
      import(
        /* webpackChunkName: "document-templates/edit" */ "@/modules/document-templates/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
