const meta = {
  module: "subdivisions"
};

export default [
  {
    path: `/subdivisions`,
    redirect: "/subdivisions/page/1"
  },
  {
    path: `/subdivisions/page`,
    redirect: "/subdivisions/page/1"
  },
  {
    path: `/subdivisions/page/:page`,
    name: "subdivisions",
    component: () =>
      import(
        /* webpackChunkName: "subdivisions" */ "@/modules/subdivisions/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/subdivisions/create`,
    name: "subdivisions/create",
    component: () =>
      import(
        /* webpackChunkName: "subdivisions/create" */ "@/modules/subdivisions/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/subdivisions/edit/:id`,
    name: "subdivisions/edit",
    component: () =>
      import(
        /* webpackChunkName: "subdivisions/edit" */ "@/modules/subdivisions/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
