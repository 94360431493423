const meta = {
  module: "guarantees"
};

export default [
  {
    path: `/guarantees`,
    redirect: "/guarantees/page/1"
  },
  {
    path: `/guarantees/page`,
    redirect: "/guarantees/page/1"
  },
  {
    path: `/guarantees/page/:page`,
    name: "guarantees",
    component: () =>
      import(
        /* webpackChunkName: "guarantees" */ "@/modules/guarantees/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
