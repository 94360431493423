const meta = {
  module: "directions"
};

export default [
  {
    path: `/directions`,
    redirect: "/directions/page/1"
  },
  {
    path: `/directions/page`,
    redirect: "/directions/page/1"
  },
  {
    path: `/directions/page/:page`,
    name: "directions",
    component: () =>
      import(
        /* webpackChunkName: "directions" */ "@/modules/directions/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/directions/create`,
    name: "directions/create",
    component: () =>
      import(
        /* webpackChunkName: "directions/create" */ "@/modules/directions/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/directions/edit/:id`,
    name: "directions/edit",
    component: () =>
      import(
        /* webpackChunkName: "directions/edit" */ "@/modules/directions/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
