const meta = {
  module: "roles"
};

export default [
  {
    path: `/roles`,
    redirect: "/roles/page/1"
  },
  {
    path: `/roles/page`,
    redirect: "/roles/page/1"
  },
  {
    path: `/roles/page/:page`,
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "roles" */ "@/modules/roles/views/index.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: `/roles/create`,
    name: "roles/create",
    component: () =>
      import(
        /* webpackChunkName: "roles/create" */ "@/modules/roles/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/roles/edit-permissions/:id`,
    name: "roles/edit-permissions",
    component: () =>
      import(
        /* webpackChunkName: "roles/create" */ "@/modules/roles/views/edit-permissions.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/roles/edit/:id`,
    name: "roles/edit",
    component: () =>
      import(
        /* webpackChunkName: "roles/edit" */ "@/modules/roles/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
