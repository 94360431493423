const meta = {
  module: "branches"
};

export default [
  {
    path: `/${meta.module}`,
    redirect: `/${meta.module}/page/1`
  },
  {
    path: `/${meta.module}/page`,
    redirect: `/${meta.module}/page/1`
  },
  {
    path: `/${meta.module}/page/:page`,
    name: `${meta.module}`,
    component: () =>
      import(
        /* webpackChunkName: "branches" */ `@/modules/${meta.module}/views/index.vue`
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/${meta.module}/create`,
    name: `${meta.module}/create`,
    component: () =>
      import(
        /* webpackChunkName: "branches/create" */ `@/modules/${meta.module}/views/create.vue`
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/${meta.module}/edit/:id`,
    name: `${meta.module}/edit`,
    component: () =>
      import(
        /* webpackChunkName: "branches/edit" */ `@/modules/${meta.module}/views/edit.vue`
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
