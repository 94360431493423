export default {
  namespaced: true,

  state: {
    info: null,
    currentRole: JSON.parse(localStorage.getItem("localRole") as any) || null
  },

  mutations: {
    set(state: any, payload: any) {
      state.info = payload;
      const credentials = JSON.parse(
        localStorage.getItem("credentials") as any
      );
      localStorage.setItem(
        "credentials",
        JSON.stringify({ ...credentials, user: payload })
      );
    },
    remove(state: any) {
      state.info = null;
    },
    setRole(state: any, payload: any) {
      state.currentRole = payload;

      if (payload) {
        localStorage.setItem("localRole", JSON.stringify(payload));
      }
    }
  },

  actions: {
    set(context: any, payload: any) {
      context.commit("set", payload);
    },
    remove(context: any) {
      context.commit("remove");
    },
    setRole({ commit }: any, payload: any) {
      commit("setRole", payload);
    },
    changeCurrentRole({ commit }: any, payload: string) {
      commit("setRole", payload);
      document.location.reload();
    }
  },

  getters: {
    info: (state: any): boolean => state.info,
    currentRole: (state: any): boolean => state.currentRole
  }
};
