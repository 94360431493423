import APIModel from "../APIModel";

export default class Statistics extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "statistics";
  }

  /* GET */

  async getData(params: object): Promise<any> {
    return await this.http.get(`${this.resourceUrl}`, params);
  }

  async getForServices(params: object = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/orders/services`, params);
  }

  async getForLast30Days(params: object = {}): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/orders/last_30_days`,
      params
    );
  }

  async getForLast12Months(params: object = {}): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/orders/last_12_months`,
      params
    );
  }

  async getForOnlineVsOffline(params: object = {}): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/orders/online_vs_offline`,
      params
    );
  }

  async getForEverydayFor30Days(params: object = {}): Promise<any> {
    return await this.http.get(
      `${this.resourceUrl}/orders/last_30_days_everyday`,
      params
    );
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  /* POST */

  /* PATCH */

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }
}
