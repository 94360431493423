const meta = {
  module: "measure-units"
};

export default [
  {
    path: `/measure-units`,
    redirect: "/measure-units/page/1"
  },
  {
    path: `/measure-units/page`,
    redirect: "/measure-units/page/1"
  },
  {
    path: `/measure-units/page/:page`,
    name: "measure-units",
    component: () =>
      import(
        /* webpackChunkName: "measure-units" */ "@/modules/measure-units/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/measure-units/create`,
    name: "measure-units/create",
    component: () =>
      import(
        /* webpackChunkName: "measure-units/create" */ "@/modules/measure-units/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/measure-units/edit/:id`,
    name: "measure-units/edit",
    component: () =>
      import(
        /* webpackChunkName: "measure-units/edit" */ "@/modules/measure-units/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
