import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Api from "@/api/API";
import confirm from "@/directives/confirm";
import fullscreen from "@/directives/fullscreen";
import i18n from "./plugins/i18n";
import moment from "moment";
// @ts-ignore
import { Chart, registerables } from "chart.js";
import handleUnsaved from "@/directives/handle-unsaved";

Vue.config.productionTip = false;

Vue.prototype.$API = Api;
Vue.prototype.$moment = moment;

Chart.register(...registerables);

Vue.directive("confirm", confirm);
Vue.directive("fullscreen", fullscreen);
Vue.directive("handle-unsaved", handleUnsaved);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
