const meta = {
  module: "cities"
};

export default [
  {
    path: `/cities`,
    redirect: "/cities/page/1"
  },
  {
    path: `/cities/page`,
    redirect: "/cities/page/1"
  },
  {
    path: `/cities/page/:page`,
    name: "cities",
    component: () =>
      import(
        /* webpackChunkName: "cities" */ "@/modules/cities/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/cities/create`,
    name: "cities/create",
    component: () =>
      import(
        /* webpackChunkName: "cities/create" */ "@/modules/cities/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/cities/edit/:id`,
    name: "cities/edit",
    component: () =>
      import(
        /* webpackChunkName: "cities/edit" */ "@/modules/cities/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
