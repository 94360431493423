const meta = {
  module: "goods-categories"
};

export default [
  {
    path: `/goods-categories`,
    redirect: "/goods-categories/page/1"
  },
  {
    path: `/goods-categories/page`,
    redirect: "/goods-categories/page/1"
  },
  {
    path: `/goods-categories/page/:page`,
    name: "goods-categories",
    component: () =>
      import(
        /* webpackChunkName: "goods-categories" */ "@/modules/goods-categories/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/goods-categories/create`,
    name: "goods-categories/create",
    component: () =>
      import(
        /* webpackChunkName: "goods-categories/create" */ "@/modules/goods-categories/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/goods-categories/edit/:id`,
    name: "goods-categories/edit",
    component: () =>
      import(
        /* webpackChunkName: "goods-categories/edit" */ "@/modules/goods-categories/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
