const meta = {
  module: "expertise-types"
};

export default [
  {
    path: `/expertise-types`,
    redirect: "/expertise-types/page/1"
  },
  {
    path: `/expertise-types/page`,
    redirect: "/expertise-types/page/1"
  },
  {
    path: `/expertise-types/page/:page`,
    name: "expertise-types",
    component: () =>
      import(
        /* webpackChunkName: "expertise-types" */ "@/modules/expertise-types/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/expertise-types/create`,
    name: "expertise-types/create",
    component: () =>
      import(
        /* webpackChunkName: "expertise-types/create" */ "@/modules/expertise-types/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/expertise-types/edit/:id`,
    name: "expertise-types/edit",
    component: () =>
      import(
        /* webpackChunkName: "expertise-types/edit" */ "@/modules/expertise-types/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
