const meta = {
  module: "aaij-orders"
};

export default [
  {
    path: `/aaij-orders`,
    redirect: "/aaij-orders/page/1?service_types=2&status=1,2,3"
  },
  {
    path: `/aaij-orders/page`,
    redirect: "/aaij-orders/page/1"
  },
  {
    path: `/aaij-orders/page/:page`,
    name: "aaij-orders",
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/modules/orders/views/index.vue"
      ),
    meta: {
      ...meta,
      withoutWrapperPadding: true
    }
  }
];
