const meta = {
  module: "employees"
};

export default [
  {
    path: `/employees`,
    redirect: "/employees/page/1"
  },
  {
    path: `/employees/page`,
    redirect: "/employees/page/1"
  },
  {
    path: `/employees/page/:page`,
    name: "employees",
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "@/modules/employees/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/employees/create`,
    name: "employees/create",
    component: () =>
      import(
        /* webpackChunkName: "employees/create" */ "@/modules/employees/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/employees/edit/:id`,
    name: "employees/edit",
    component: () =>
      import(
        /* webpackChunkName: "employees/edit" */ "@/modules/employees/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
