const meta = {
  module: "orders"
};

export default [
  {
    path: `/orders`,
    redirect: "/orders/page/1"
  },
  {
    path: `/orders/page`,
    redirect: "/orders/page/1"
  },
  {
    path: `/orders/page/:page`,
    name: "orders",
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/modules/orders/views/index.vue"
      ),
    meta: {
      ...meta,
      withoutWrapperPadding: true
    }
  },
  {
    path: `/orders/history/:id`,
    name: "orders/history",
    component: () =>
      import(
        /* webpackChunkName: "orders/create" */ "@/modules/orders/views/history.vue"
      ),
    meta: {
      ...meta,
      action: "history"
    }
  },
  {
    path: `/orders/expertise/:id`,
    name: "orders/expertise",
    component: () =>
      import(
        /* webpackChunkName: "orders/expertise" */ "@/modules/orders/components/order_types/EXP/Create.vue"
      ),
    meta: {
      ...meta,
      action: "expertise"
    }
  },
  {
    path: `/orders/document/:id`,
    name: "orders/document",
    component: () =>
      import(
        /* webpackChunkName: "orders/document/:id" */ "@/modules/orders/views/orderTemplate.vue"
      ),
    meta: {
      ...meta,
      action: "expertise"
    }
  },
  {
    path: `/orders/online-payments`,
    name: "orders/online-payments",
    component: () =>
      import(
        /* webpackChunkName: "orders/online-payments" */ "@/modules/orders/views/onlinePayments.vue"
      ),
    meta: {
      ...meta,
      action: "online-payments"
    }
  },
  {
    path: `/orders/edit/:id`,
    name: "orders/edit",
    component: () =>
      import(
        /* webpackChunkName: "orders/edit" */ "@/modules/orders/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  },
  {
    path: `/orders/preview/:id`,
    name: "orders/preview",
    component: () =>
      import(
        /* webpackChunkName: "orders/preview" */ "@/modules/orders/views/previewOrder.vue"
      ),
    meta: {
      ...meta,
      action: "preview"
    }
  },
  {
    path: `/orders/create`,
    name: "orders/create",
    component: () =>
      import(
        /* webpackChunkName: "orders/create" */ "@/modules/orders/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  }
];
