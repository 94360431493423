const meta = {
  module: "goods"
};

export default [
  {
    path: `/goods`,
    redirect: "/goods/page/1"
  },
  {
    path: `/goods/page`,
    redirect: "/goods/page/1"
  },
  {
    path: `/goods/page/:page`,
    name: "goods",
    component: () =>
      import(/* webpackChunkName: "goods" */ "@/modules/goods/views/index.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: `/goods/create`,
    name: "goods/create",
    component: () =>
      import(
        /* webpackChunkName: "goods/create" */ "@/modules/goods/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/goods/edit/:id`,
    name: "goods/edit",
    component: () =>
      import(
        /* webpackChunkName: "goods/edit" */ "@/modules/goods/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
