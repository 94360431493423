const meta = {
  module: "statistics",
  withoutWrapperPadding: true
};

export default [
  {
    path: `/statistics2`,
    name: "statistics2",
    component: () =>
      import(
        /* webpackChunkName: "statistics" */ "@/modules/statistics/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/statistics`,
    name: "statistics",
    component: () =>
      import(
        /* webpackChunkName: "statistics" */ "@/modules/statistics/views/index2.vue"
      ),
    meta: {
      ...meta
    }
  }
];
