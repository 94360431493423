import APIModel from "../APIModel";

export default class Employees extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "employees";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}`, params);
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getCertificatesList(): Promise<any> {
    return await this.http.get(`certificate_types/list`);
  }

  async getList(params = {} as any, headers = {} as any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .setHeaders(headers)
      .get(`${this.resourceUrl}/list`, params);
  }

  async getServiceInfo(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/show/${id}`);
  }

  async liveSearch(query: any): Promise<any> {
    //todo убрать эти параметры параметры, сделать нормально на бэке
    //todo фильтры указывать в компоненте где вызывается апи
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(
        `${this.resourceUrl}/live_search?fields[value]=id&fields[text]=system_name&route=v1.services.index.get&filter[system_name]=${query}`,
        {}
      );
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }
}
