const meta = {
  module: "banks"
};

export default [
  {
    path: `/banks`,
    redirect: "/banks/page/1"
  },
  {
    path: `/banks/page`,
    redirect: "/banks/page/1"
  },
  {
    path: `/banks/page/:page`,
    name: "banks",
    component: () =>
      import(/* webpackChunkName: "banks" */ "@/modules/banks/views/index.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: `/banks/create`,
    name: "banks/create",
    component: () =>
      import(
        /* webpackChunkName: "banks/create" */ "@/modules/banks/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/banks/edit/:id`,
    name: "banks/edit",
    component: () =>
      import(
        /* webpackChunkName: "banks/edit" */ "@/modules/banks/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
