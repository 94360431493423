import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import ability from "@/plugins/ability";
import API from "@/api/API";
import { Store } from "vuex";

class RouterMiddleware {
  private router: VueRouter;
  private store: Store<unknown>;
  private timeoutList: Array<any>;
  private readonly timeoutDelay: number;

  constructor(router: VueRouter) {
    this.router = router;
    this.store = store;
    this.timeoutList = [];
    this.timeoutDelay = 10000;
  }

  public apply() {
    this.applyBeforeEach();
    this.applyAfterEach();
  }

  private applyAfterEach() {
    this.router.afterEach((to: Route, from: Route) => {
      this.clearTransitionTimeout();
      this.hideGlobalLoader();

      if (to.meta?.errorPage) {
        history.replaceState(
          {},
          document.title,
          `/${
            this.store.getters["localization/getCurrent"]
          }${to.redirectedFrom || from.path}`
        );
      }
    });
  }

  private applyBeforeEach() {
    this.router.beforeEach(
      async (to: Route, from: Route, next: NavigationGuardNext) => {
        if (to.meta && to.name === "errorUnknown") {
          to.meta.layout = "auth";
          next();
          return;
        }

        const isAuthorized = this.store.getters[
          "authentication/hasAccessToken"
        ];

        const user = this.store.getters["user/info"];

        await this.store.dispatch("navigation/setModule", to.name);

        if (!to.meta?.withoutCredentials && !isAuthorized) {
          next(`/auth/login`);
          return;
        }

        // todo fix infinite read request (Tudor)
        // if (isAuthorized && !user) {
        //   await this.loadUserInfo();
        // }

        await this.loadUserPermissions(to);

        if (from.name === null) {
          this.setTransitionTimeout(() => {
            next("/error/unknown");
          });
        }

        if (
          to.name === "application" &&
          store.getters["user/info"].has_application
        ) {
          next("/preview");
          return;
        }

        next();
      }
    );
  }

  private setTransitionTimeout(callback: Function) {
    const timeout = setTimeout(() => {
      callback();
      this.clearTransitionTimeout();
    }, this.timeoutDelay);

    this.timeoutList.push(timeout);
  }

  private clearTransitionTimeout() {
    for (const timeout of this.timeoutList) {
      clearTimeout(timeout);
    }
  }

  private hideGlobalLoader() {
    this.store.dispatch("preloader/hideGlobal");
  }

  private async loadUserInfo() {
    try {
      const credentials = await this.store.getters[
        "authentication/credentials"
      ];
      const response = await API.users().checkStatus(credentials.user.id);

      await this.store.dispatch("user/set", response);

      setTimeout(async () => {
        await this.loadUserInfo();
      }, 20000);
    } catch (e) {
      await Promise.reject("Unknown error");
    }
  }

  private async loadUserPermissions(to: Route) {
    const permissions = [];
    const { user } = this.store.getters["authentication/credentials"];

    if (!user) {
      return;
    }

    try {
      const allModulePermissions = await import(
        `@/modules/${to.meta?.module}/config/permissions.ts`
      );
      const modulePermissions = allModulePermissions.default[user.position];

      if (modulePermissions) {
        permissions.push(...modulePermissions);
      }
    } catch (e) {
      await Promise.resolve();
    } finally {
      const globalPermissions = require.context(
        "@/permissions",
        true,
        /[A-Za-z0-9-_,\s]+\.ts$/i
      );

      for (const path of globalPermissions.keys()) {
        const allGlobalPermissions = await import(
          `@/permissions/${path.substring(2)}`
        );
        const globalPermissions = allGlobalPermissions.default[user.position];

        if (globalPermissions) {
          permissions.push(...globalPermissions);
        }
      }

      ability.update(permissions);
    }
  }
}

export default RouterMiddleware;
