const meta = {
  module: "email-templates"
};

export default [
  {
    path: `/email-templates`,
    redirect: "/email-templates/page/1"
  },
  {
    path: `/email-templates/page`,
    redirect: "/email-templates/page/1"
  },
  {
    path: `/email-templates/page/:page`,
    name: "email-templates",
    component: () =>
      import(
        /* webpackChunkName: "email-templates" */ "@/modules/email-templates/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/email-templates/edit/:id`,
    name: "email-templates/edit",
    component: () =>
      import(
        /* webpackChunkName: "email-templates/edit" */ "@/modules/email-templates/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
