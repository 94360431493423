const meta = {
  module: "variables"
};

export default [
  {
    path: `/variables`,
    redirect: "/variables/page/1"
  },
  {
    path: `/variables/page`,
    redirect: "/variables/page/1"
  },
  {
    path: `/variables/page/:page`,
    name: "variables",
    component: () =>
      import(
        /* webpackChunkName: "variables" */ "@/modules/variables/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/variables/create`,
    name: "variables/create",
    component: () =>
      import(
        /* webpackChunkName: "variables/create" */ "@/modules/variables/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/variables/edit/:id`,
    name: "variables/edit",
    component: () =>
      import(
        /* webpackChunkName: "variables/edit" */ "@/modules/variables/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
