const meta = {
  module: "work-positions"
};

export default [
  {
    path: `/work-positions`,
    redirect: "/work-positions/page/1"
  },
  {
    path: `/work-positions/page`,
    redirect: "/work-positions/page/1"
  },
  {
    path: `/work-positions/page/:page`,
    name: "work-positions",
    component: () =>
      import(
        /* webpackChunkName: "work-positions" */ "@/modules/work-positions/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/work-positions/create`,
    name: "work-positions/create",
    component: () =>
      import(
        /* webpackChunkName: "work-positions/create" */ "@/modules/work-positions/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/work-positions/edit/:id`,
    name: "work-positions/edit",
    component: () =>
      import(
        /* webpackChunkName: "work-positions/edit" */ "@/modules/work-positions/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
