import Auth from "@/api/system/Auth";
import Users from "@/api/system/Users";
import Roles from "@/api/system/Roles";
import Countries from "@/api/system/Countries";
import Banks from "@/api/system/Banks";
import TransportTypes from "@/api/system/TransportTypes";
import PurposeOfUse from "@/api/system/PurposeOfUse";
import PackingCategories from "@/api/system/PackingCategories";
import Clients from "@/api/system/Clients";
import Variables from "@/api/system/Variables";
import Orders from "@/api/system/Orders";
import Invoices from "@/api/system/Invoices";
import Currency from "@/api/system/Currency";
import Goods from "@/api/system/Goods";
import Employees from "@/api/system/Employees";
import WorkPositions from "@/api/system/WorkPositions";
import Services from "@/api/system/Services";
import Guarantees from "@/api/system/Guarantees";
import Payments from "@/api/system/Payments";
import Branches from "@/api/system/Branches";
import Cities from "@/api/system/Cities";
import Subdivisions from "@/api/system/Subdivisions";
import EmailTemplates from "@/api/system/EmailTemplates";
import DocumentTemplates from "@/api/system/DocumentTemplates";
import Statistics from "@/api/system/Statistics";
import Profile from "@/api/system/Profile";
import GoodsCategories from "@/api/system/GoodsCategories";
import ExpertiseTypes from "@/api/system/ExpertiseTypes";
import OrganizationForms from "@/api/system/OrganizationForms";
import ExchangeRates from "@/api/system/ExchangeRates";
import MeasureUnits from "@/api/system/MeasureUnits";
import Notifications from "@/api/system/Notifications";
import MPower from "@/api/system/MPower";
import Directions from "@/api/system/Directions";
import ServiceTypes from "@/api/system/ServiceTypes";

export default {
  auth: () => new Auth(),
  users: () => new Users(),
  roles: () => new Roles(),
  countries: () => new Countries(),
  banks: () => new Banks(),
  transportTypes: () => new TransportTypes(),
  purposeOfUse: () => new PurposeOfUse(),
  packingCategories: () => new PackingCategories(),
  clients: () => new Clients(),
  variables: () => new Variables(),
  orders: () => new Orders(),
  invoices: () => new Invoices(),
  currency: () => new Currency(),
  goods: () => new Goods(),
  employees: () => new Employees(),
  workPositions: () => new WorkPositions(),
  services: () => new Services(),
  guarantees: () => new Guarantees(),
  payments: () => new Payments(),
  branches: () => new Branches(),
  cities: () => new Cities(),
  subdivisions: () => new Subdivisions(),
  emailTemplates: () => new EmailTemplates(),
  statistics: () => new Statistics(),
  documentTemplates: () => new DocumentTemplates(),
  expertiseTypes: () => new ExpertiseTypes(),
  profile: () => new Profile(),
  goodsCategories: () => new GoodsCategories(),
  organizationForms: () => new OrganizationForms(),
  exchangeRates: () => new ExchangeRates(),
  measureUnits: () => new MeasureUnits(),
  notifications: () => new Notifications(),
  MPower: () => new MPower(),
  directions: () => new Directions(),
  serviceTypes: () => new ServiceTypes()
};
