
import Vue from "vue";
import AppLayout from "@/layouts/index.vue";
import PageLoader from "@/components/navigation/PageLoader.vue";
//@ts-ignore
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

Vue.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme);

export default Vue.extend({
  name: "App",

  components: { PageLoader, AppLayout },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setPageTitle();
      }
    }
  },

  computed: {
    showGlobalLoader(): boolean {
      return this.$store.getters["preloader/showGlobal"];
    }
  },

  methods: {
    setPageTitle() {
      const localizedTitle = `${this.$route?.meta?.module}`;

      let title = localizedTitle;

      if (localizedTitle.includes(".")) {
        title = this.startCase(this.$route?.meta?.module);
      }

      document.title = `${this.$t("cci_name")}`;

      // if (title) {
      //   document.title = `${process.env.VUE_APP_NAME} - ${title}`;
      // } else {
      //   document.title = `${process.env.VUE_APP_NAME}`;
      // }
    }
  }
});
