const meta = {
  module: "organization-forms"
};

export default [
  {
    path: `/organization-forms`,
    redirect: "/organization-forms/page/1"
  },
  {
    path: `/organization-forms/page`,
    redirect: "/organization-forms/page/1"
  },
  {
    path: `/organization-forms/page/:page`,
    name: "organization-forms",
    component: () =>
      import(
        /* webpackChunkName: "organization-forms" */ "@/modules/organization-forms/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/organization-forms/create`,
    name: "organization-forms/create",
    component: () =>
      import(
        /* webpackChunkName: "organization-forms/create" */ "@/modules/organization-forms/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/organization-forms/edit/:id`,
    name: "organization-forms/edit",
    component: () =>
      import(
        /* webpackChunkName: "organization-forms/edit" */ "@/modules/organization-forms/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
