import APIModel from "../APIModel";

export default class Clients extends APIModel {
  private readonly resourceUrl: string;

  constructor() {
    super();
    this.resourceUrl = "clients";
  }

  async getData(params: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 400)
      .get(`${this.resourceUrl}`, params);
  }

  async exportClients(): Promise<any> {
    return await this.http.get(`clients/export`, {});
  }

  async getForEdit(id: number): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/edit`);
  }

  async getList(): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/list`, {});
  }

  async getClientTypes(): Promise<any> {
    return await this.http
      .ignoreErrorHandler(404, 500)
      .get(`${this.resourceUrl}/types`, {});
  }

  async liveSearch(query: string): Promise<any> {
    //todo убрать эти параметры параметры, сделать нормально на бэке
    //todo фильтры указывать в компоненте где вызывается апи
    const params = {
      fields: {
        value: "id",
        text: "idno"
      },
      route: "v1.clients.index.get",
      filter: {
        idno: query
      }
    };
    return await this.http
      .ignoreErrorHandler(500, 422, 404)
      .get(`${this.resourceUrl}/live_search`, params);
  }

  async show(id: number, headers: any = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500, 404)
      .setHeaders(headers)
      .get(`${this.resourceUrl}/${id}/show`, {});
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${id}?_method=PATCH`, payload);
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async delete(id: any): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, { id });
  }

  async getExpertises(id: any, params: any = {}): Promise<any> {
    return await this.http.get(`${this.resourceUrl}/${id}/exp_request`, params);
  }
}
