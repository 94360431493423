export default {
  light: {
    primary: "#0064A8",
    secondary: "#262B33",
    "light-secondary": "#666666",
    light: "#EBEFF2",
    selected: "#F5F6F8",
    grayish: "#e3e4e6",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    "expanded-row-header": "#eaeaea",
    "expanded-row": "#f8f8f8",
    text: "#444444",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#ffffff",
    "nav-bar-icons": "#ffffff",
    /* SideBar Colors */
    "side-bar-text-color": "#ffffff",
    "side-bar-header-color": "#ffffff",
    "side-bar-color": "#337AB7",
    "side-bar-hovered-color": "#ffffff",
    "side-bar-selected-text-color": "#eeeeee",
    "side-bar-accent-color": "#749fc3",
    "table-header-color": "#f6f7f7",
    "table-border-color": "#cccccc",
    "card-color": "#ffffff",
    "scroll-color": "#e0dfe1",
    "border-color": "#929292",
    "active-card-color": "#e3eefc",
    "black-text": "#000000",
    "white-text": "#ffffff"
  },
  dark: {
    primary: "#0064A8",
    secondary: "#262B33",
    "light-secondary": "#666666",
    light: "#EBEFF2",
    selected: "#F5F6F8",
    grayish: "#363738",
    "light-gray": "#81848B",
    "lighter-gray": "#ECECEC",
    text: "#dadada",
    blue: "#46BCFF",
    darkBlue: "#00233C",
    purple: "#965BF7",
    error: "#F7685B",
    success: "#2ED47A",
    warning: "#FFB948",
    hover: "#393c41",
    "odd-row": "#f9f9f9",
    /* NavBar Colors */
    "nav-bar-color": "#1e1e1e",
    "nav-bar-icons": "#ffffff",
    /* SideBar Colors */
    "side-bar-text-color": "#81848B",
    "side-bar-header-color": "#666666",
    "side-bar-color": "#262B33",
    "side-bar-hovered-color": "#393c41",
    "side-bar-selected-text-color": "#F5F6F8",
    "side-bar-accent-color": "#1b1f24",
    "table-header-color": "#1e1e1e",
    "table-border-color": "#8c8c8c",
    "card-color": "#1e1e1e",
    "scroll-color": "#484848",
    "border-color": "#fff",
    "active-card-color": "#232d38",
    "black-text": "#fff",
    "white-text": "#000"
  }
};
