import Vue, { VNode } from "vue";
import Confirm from "@/components/dialogs/ConfirmDialog.vue";
import { DirectiveBinding } from "vue/types/options";
import i18n from "../plugins/i18n";
const ConfirmDialog = Vue.extend(Confirm);

const showConfirm = (vnode: VNode) => {
  return new Promise(resolve => {
    const component: any = new ConfirmDialog({
      propsData: {
        executeAction: resolve
      }
    });
    const root: any = document.createElement("div");
    const app: any = document.getElementById("app");
    const oldRoot: any = (app as any).querySelector("#custom-directive-modal");

    root.setAttribute("id", "custom-directive-modal");
    component.$vuetify = vnode.context?.$vuetify;
    component.$mount();
    root.appendChild(component.$el);

    if (oldRoot) {
      app.removeChild(oldRoot);
      component.$destroy();
      app.appendChild(root);
    } else {
      app.appendChild(root);
    }

    component.open(
      1,
      i18n.t("global_alert.confirm_unsaved_title"),
      i18n.t("global_alert.confirm_unsaved")
    );
  });
};

export default {
  bind(el: any, binding: DirectiveBinding, vnode: VNode) {
    let existRouterHook = false;

    if (vnode.componentInstance) {
      vnode.componentInstance.checkUnsaved = () => {
        return new Promise((resolve, reject) => {
          if (!existRouterHook) {
            resolve();
            return;
          }

          const app: any = document.getElementById("app");
          const oldRoot: any = app.querySelector("#custom-directive-modal");

          showConfirm(vnode)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              if (oldRoot) {
                app.removeChild(oldRoot);
              }
            });
        });
      };
      vnode.componentInstance.removeGuard = () => {
        if (existRouterHook) {
          const router: any = vnode.context?.$router;

          router.beforeHooks.shift();
          window.onbeforeunload = null;
          existRouterHook = false;
        }
      };
      vnode.componentInstance.updateGuard = () => {
        vnode.componentInstance?.removeGuard();
        const router: any = vnode.context?.$router;

        for (const input of vnode.componentInstance?.inputs) {
          input.$once("input", () => {
            window.onbeforeunload = () => {
              return i18n.t("global_alert.confirm_unsaved");
            };

            router.beforeHooks.unshift(
              async (from: any, to: any, next: any) => {
                const app: any = document.getElementById("app");
                const oldRoot: any = app.querySelector(
                  "#custom-directive-modal"
                );

                await showConfirm(vnode);
                next();
                router.beforeHooks.shift();
                window.onbeforeunload = null;
                existRouterHook = false;

                if (oldRoot) {
                  app.removeChild(oldRoot);
                }
              }
            );
            existRouterHook = true;
          });
        }
        existRouterHook = true;
      };
    }

    for (const input of vnode.componentInstance?.inputs) {
      input.$once("input", () => {
        if (!existRouterHook) {
          const router: any = vnode.context?.$router;
          window.onbeforeunload = () => {
            return i18n.t("global_alert.confirm_unsaved");
          };

          router.beforeHooks.unshift(async (from: any, to: any, next: any) => {
            const app: any = document.getElementById("app");
            const oldRoot: any = app.querySelector("#custom-directive-modal");

            await showConfirm(vnode);
            next();
            router.beforeHooks.shift();
            window.onbeforeunload = null;
            existRouterHook = false;

            if (oldRoot) {
              app.removeChild(oldRoot);
            }
          });
          existRouterHook = true;
        }
      });
    }
  }
};
