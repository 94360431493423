const meta = {
  module: "countries"
};

export default [
  {
    path: `/countries`,
    redirect: "/countries/page/1"
  },
  {
    path: `/countries/page`,
    redirect: "/countries/page/1"
  },
  {
    path: `/countries/page/:page`,
    name: "countries",
    component: () =>
      import(
        /* webpackChunkName: "countries" */ "@/modules/countries/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/countries/create`,
    name: "countries/create",
    component: () =>
      import(
        /* webpackChunkName: "countries/create" */ "@/modules/countries/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/countries/edit/:id`,
    name: "countries/edit",
    component: () =>
      import(
        /* webpackChunkName: "countries/edit" */ "@/modules/countries/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
