const meta = {
  module: "exchange-rates"
};

export default [
  {
    path: `/exchange-rates`,
    redirect: "/exchange-rates/page/1"
  },
  {
    path: `/exchange-rates/page`,
    redirect: "/exchange-rates/page/1"
  },
  {
    path: `/exchange-rates/page/:page`,
    name: "exchange-rates",
    component: () =>
      import(
        /* webpackChunkName: "exchange-rates" */ "@/modules/exchange-rates/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/exchange-rates/create`,
    name: "exchange-rates/create",
    component: () =>
      import(
        /* webpackChunkName: "exchange-rates/create" */ "@/modules/exchange-rates/views/create.vue"
      ),
    meta: {
      ...meta,
      action: "create"
    }
  },
  {
    path: `/exchange-rates/edit/:date`,
    name: "exchange-rates/edit",
    component: () =>
      import(
        /* webpackChunkName: "exchange-rates/edit" */ "@/modules/exchange-rates/views/edit.vue"
      ),
    meta: {
      ...meta,
      action: "edit"
    }
  }
];
